import React from 'react';
import { i18n } from '../../../../../common/services/i18n';
import { environment } from '../../../../../environments/environment';

export default function Claim() {
    if (environment.project === 'lipno') {
        return (
            <div style={{marginTop: '30px'}}>
                {i18n.language === 'cz' && (
                    <React.Fragment>
                        <p>Reklamace služeb poskytovaných na základě zakoupených skipasů /bikepasů / jízdenek na lanovou dráhu / vstupenek nebo kombinací se řídí obchodními podmínkami vztahujícími se k jednotlivým skipasům / bikepasům / jízdenkám na lanovou dráhu / vstupenkám nebo kombinacím vydanými LS s.r.o. a Obchodními partnery LS s.r.o..</p>
                        <p>REKLAMAČNÍ ŘÁD: LS s.r.o. odpovídá za vady služeb ve smyslu platných právních předpisů, zejména občanského zákoníku, ve spojení s příslušnými ustanoveními zákona č. 634/1992 Sb., o ochraně spotřebitele, ve znění pozdějších předpisů a ostatních obecně závazných právních předpisů. V případě, že zakoupená služba či zboží obsahuje vady, je člen programu LIPNOCARD povinen uplatnit nároky z vad (reklamaci) ve lhůtě tří dnů poté, co zjistí důvody pro uplatnění reklamace, v případě služby pak v den, v němž nebyla služba poskytnuta v dohodnutém nebo běžném rozsahu, kvalitě, množství a termínu, jinak právo na reklamaci zaniká, a to osobně v závislosti na reklamované službě na provozech LS s.r.o., Infocentru Lipno nebo zasláním e-mailové zprávy na reklamace@lipnocard.cz nebo písemnou reklamací formou poštovní zásilky na adresu LIPNO SERVIS s.r.o, Lipno nad Vltavou 307, 382 78 Lipno nad Vltavou. LS s.r.o. po přezkoumání uplatněné reklamace rozhodne o způsobu vyřízení reklamace ve lhůtě 30 dnů ode dne jejího uplatnění. Člen programu LIPNOCARD je povinen při vyřizování reklamace poskytnout LS s.r.o. potřebnou součinnost vyžadovanou LS s.r.o. Ve lhůtě na vyřízení reklamace zašle LS s.r.o. členovi programu LIPNOCARD formou e-mailu nebo prostřednictvím poštovní zásilky vyjádření k reklamaci a o způsobu odstranění vad reklamované služby či zboží.</p>
                        <p>V případě, že člen programu LIPNOCARD není spokojen se způsobem, kterým LS s.r.o. vyřídil jeho reklamaci nebo se domnívá, že LS s.r.o. porušil jeho práva, má právo obrátit se na LS s.r.o. s žádostí o nápravu. V případě, že dojde mezi LS s.r.o. a spotřebitelem ke vzniku spotřebitelského sporu ze smlouvy o poskytování služeb, který se nepodaří vyřešit vzájemnou dohodou, může spotřebitel podat návrh na mimosoudní řešení takového sporu určenému subjektu mimosoudního řešení spotřebitelských sporů, kterým je Česká obchodní inspekce, Ústřední inspektorát – oddělení ADR, Štěpánská 15, 120 00 Praha 2, Email: adr@coi.cz, Web: adr.coi.cz.</p>
                    </React.Fragment>
                )}
                {i18n.language === 'de' && (
                    <React.Fragment>
                        <p>Die Reklamationen der aufgrund der gekauften Skipässe / Bikepässe / Sesselbahn-Fahrkarten / Eintrittskarten oder Kombinationen zu gewährenden Dienstleistungen richten sich nach den Geschäftsbedingungen für die einzelnen Skipässe / Bikepässe / Sesselbahn-Fahrkarten / Eintrittskarten oder Kombinationen, die durch LS s.r.o. und die Geschäftspartner von LS s.r.o. ausgegeben werden.</p>
                        <p>REKLAMATIONSORDNUNG: LS s.r.o. haftet für die Mängel der Dienstleistungen im Sinne der gültigen Rechtsvorschriften, insbesondere des Bürgerlichen Gesetzbuchs, in Verbindung mit den einschlägigen Bestimmungen des Gesetzes Nr. 634/1992 Slg., über Konsumentenschutz, in der Fassung späterer Vorschriften und sonstiger allgemein verbindlicher Rechtsvorschriften. Wenn die gekaufte Dienstleistung oder Ware Mängel aufweist, ist das Mitglied des Programms LIPNOCARD verpflichtet die Ansprüche aus Mängeln (die Mängelrüge) innerhalb von drei Tagen nach der Feststellung der Gründe für die Mängelrüge geltend zu machen, bei einer Dienstleistung dann am Tag, wann die Dienstleistung im vereinbarten oder üblichen Umfang, Qualität, Menge und Termin nicht erbracht wurde, sonst erlischt das Recht auf Reklamation, und zwar persönlich in Abhängigkeit von der gerügten Dienstleistung in den Betrieben der Gesellschaft LS s.r.o., im Informationszentrum Lipno oder per E-Mail an reklamace@lipnocard.cz oder schriftlich per Post an LIPNO SERVIS s.r.o., Lipno nad Vltavou 307, 382 78 Lipno nad Vltavou. LS s.r.o. entscheidet nach der Überprüfung der geltend gemachten Reklamation innerhalb von 30 Tagen nach der Mängelrüge über die Art und Weise der Abwicklung. Das Mitglied des Programms LIPNOCARD ist verpflichtet der Gesellschaft LS s.r.o. die erforderliche, seitens LS s.r.o. verlangte Mitarbeit bei der Abwicklung der Reklamation zu leisten. Innerhalb der Abwicklungsfrist für die Reklamation sendet LS s.r.o. dem Mitglied des Programms LIPNOCARD die Stellungnahme zur Reklamation und zur Art und Weise der Behebung der gerügten Leistungs- oder Warenmängel per E-Mail oder per Post.</p>
                        <p>Falls das Mitglied des Programms LIPNOCARD mit der Abwicklung der Reklamation seitens LS s.r.o. nicht zufrieden ist oder wenn das Mitglied behauptet, dass LS s.r.o. seine Rechte verletzt hat, kann es sich an LS s.r.o. mit Antrag um Korrektur wenden. Sollte ein Verbraucherstreit aus dem Dienstleistungsvertrag zwischen LS s.r.o. und dem Verbraucher eintreten, der durch einvernehmliche Vereinbarung nicht gelöst werden kann, kann der Verbraucher einen Antrag an außergerichtliche Lösung eines solchen Streits beim bestimmten Subjekt der außergerichtlichen Lösungen von Verbraucherstreitigkeiten einreichen, also bei der Tschechischen Handelsinspektion, Zentralinspektorat - Abteilung ADR, Štěpánská 15, 120 00 Praha 2, e-Mail: adr@coi.cz, Web: adr.coi.cz.</p>
                    </React.Fragment>
                )}
                {i18n.language === 'en' && (
                    <React.Fragment>
                        <p>Complaints of services provided on the basis of purchased skipassess / bikepasses / tickets to the chairlift / entrance tickets or a combination of tickets are governed by business terms and conditions relating to the individual skipassess / bikepasses / tickets to the chairlift / entrance tickets or a combination of tickets issued by LS s.r.o. and Business Partners of LS s.r.o.</p>
                        <p>COMPLAINT RULES: LS s.r.o. is liable for defects of services in compliance with valid legal regulations, chiefly the Civil Code, in conjunction with applicable provisions of Act No. 634/1992 Coll., on the Protection of Customers, as amended by later regulations, and other legislation of general application. In the event a service or goods purchased contain defects, a Member of the LIPNOCARD Programme is obliged to exercise claims from defects (a complaint) within three days after the reasons for exercising a complaint are ascertained, or, in case of a service, on the day when the service was not provided in the agreed or customary scope, quality, quantity and date; failing that, the right to lodge a complaint shall be lost. Complaints must be asserted according to the nature of the given service either in person in establishments of LS s.r.o., Information Centre Lipno, by sending an e-mail to reklamace@lipnocard.cz, or by sending a complaint in writing in the form of a postal consignment to the address LIPNO SERVIS s.r.o, Lipno nad Vltavou 307, 382 78 Lipno nad Vltavou. Based on the examination of a complaint lodged, LS s.r.o. shall decide on the manner of solving the complaint within 30 days of the day the complaint is asserted. During the complaint procedure, a Member of the LIPNOCARD Programme is obliged to provide LS s.r.o. with necessary assistance as required by LS s.r.o. Within the period of the complaint examination, a Member of the LIPNOCARD Programme shall be sent by LS s.r.o. the company’s opinion of the complaint and the method of remedy of the defects of the service or goods under complaint, specifically by e-mail or post.</p>
                        <p>If a Member of the LIPNOCARD Programme is not satisfied with the solution of his/her complaint provided by LS s.r.o. or believes that his/her rights have been violated by LS s.r.o., he/she has a right to contact LS s.r.o. and require remedy. In case of a consumer dispute from a contract of services arises between LS s.r.o. and the given consumer, and this dispute cannot be solved amicably, the consumer may file a motion for out-of-court resolution of such dispute with an entity concerned with out-of-court resolutions of consumer disputes, i.e. the Czech Inspection and Trade Authority [ČOI], Central Inspectorate - ADR Department, Štěpánská 15, 120 00 Praha 2, Email: adr@coi.cz, Web: adr.coi.cz.</p>
                    </React.Fragment>
                )}
            </div>
        );
    } else if (environment.project === 'annaberg') {
        return (
            <React.Fragment>
                {i18n.language === 'en' && (
                    <React.Fragment>
                        <h3>Claim</h3>
                    </React.Fragment>
                )}
                {i18n.language === 'de' && (
                    <React.Fragment>
                        <h3>Claim</h3>
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }

    return (<></>);
}
