import { Card, Divider, Icon } from 'antd';
import React, { useState } from 'react';
import { oc } from 'ts-optchain';
import moment from 'moment';
import cn from 'classnames';

import { ProductVoucherValidation } from '../../../features/shopping/ducks';
import { CartEntry, Select } from '../../components';
import { AddVoucherModal } from '../../modals';
import { ProductSelectionPopulated } from '../../models/Cart';
import { i18n } from '../../services/i18n';
import LocalizedString from '../LocalizedString';
import { FormatSelect, ProductAssignerLabel } from './components';
import EntryUserInfoModal from '../../modals/EntryUserInfoModal';
import { UserEntryFormValues } from '../../models/User';
import { needUserInfoForEntry } from '../../utils/needUserInfoForEntry';
import { METHOD_MAP } from './components/FormatSelect/helpers';
import { EntryMethod } from '../../models/NewTransaction';
import { environment } from '../../../environments/environment';

interface Props {
    selection: ProductSelectionPopulated;
    voucherValidation: ProductVoucherValidation;
    onVoucherChange(id: string, voucher?: string): void;
    onAddEntryUserInfo?(id: string, data?: UserEntryFormValues): void;
    onRemove(id: string): void;
    onDeleteTransactionVoucher(): void;
    onValidateVoucher: (payload: { selection: ProductSelectionPopulated; voucher: string }) => void;
    onResetValidateVoucher: () => void;
}

function ProductAssigner(props: Props) {
    const [userInfoModal, setUserInfoModal] = useState(false);
    const [addVoucherModal, setAddVoucherModal] = useState(false);

    function handleRemoveClick() {
        if (props.selection.product.type === 'transaction_voucher_product') {
            props.onDeleteTransactionVoucher && props.onDeleteTransactionVoucher();
        } else {
            props.onRemove && props.onRemove(props.selection.id);
        }
    }

    function handleApplyDiscount() {
        setAddVoucherModal(true);
    }

    function handleApplyDiscountCancel() {
        setAddVoucherModal(false);
        props.onResetValidateVoucher();
    }

    function handleApplyDiscountOk() {
        props.onVoucherChange(props.selection.id, oc(props).voucherValidation.voucher() || undefined);
        props.onResetValidateVoucher();
        setAddVoucherModal(false);
    }

    function handleVoucherValidate(voucher: string) {
        props.onValidateVoucher({ selection: props.selection, voucher });
    }

    function handleDiscountRemove() {
        props.onVoucherChange(props.selection.id, undefined);
    }

    const handleEntryUserInfoSubmit = (data: UserEntryFormValues) => {
        setUserInfoModal(false);
        props.onAddEntryUserInfo && props.onAddEntryUserInfo(props.selection.id, data);
    };

    const getUserDetailsLabel = () => {
        if (props.selection.userData) {
            const { firstName, lastName, birthday: cardBirthday, email } = props.selection.userData;
            let result = `${firstName} ${lastName}`;

            if (cardBirthday) {
                result += `, ${-moment(cardBirthday, 'YYYY-MM-DD').diff(moment(), 'years')} ${i18n.t('productAssigment.userDetails.yearsOld')}`;
            }

            if (email) {
                result += `, ${email}`;
            }

            return result;
        }

        return i18n.t('productAssigment.userDetails.placeholder');
    };

    const displayMinCard = () => {
        return ['transaction_voucher_product', 'card-product'].includes(props.selection.product.type);
    };

    const hideMethodSelect = () => {
        const options = Object.values(METHOD_MAP).filter(v => oc(props.selection).product.tags([]).includes(v));

        if (!environment.config.hideProductSelectionForPrintOnly) {
            return false;
        }

        return options.length === 1 && options[0] === METHOD_MAP[EntryMethod.Print];
    };

    return (
        <div className="product-assigner">
            <AddVoucherModal
                visible={addVoucherModal}
                onCancel={handleApplyDiscountCancel}
                onValidate={handleVoucherValidate}
                verified={props.voucherValidation.valid}
                needMore={props.voucherValidation.needMore}
                error={props.voucherValidation.error}
                onOk={handleApplyDiscountOk}
            />
            <EntryUserInfoModal
                data={props.selection.userData}
                method={oc(props).selection.assigment.method()}
                visible={userInfoModal}
                onCancel={() => setUserInfoModal(false)}
                onSubmit={handleEntryUserInfoSubmit}
            />
            <Card
                title={
                    <div className="product-assigner__title">
                        <LocalizedString value={props.selection.product.name} />{' '}
                        <LocalizedString value={props.selection.product.description} />
                    </div>
                }
                extra={!displayMinCard() && <Icon type="close" onClick={handleRemoveClick} />}
                className={cn({ 'min-card': displayMinCard() })}
            >
                <CartEntry
                    selection={props.selection}
                    onDiscountRemove={handleDiscountRemove}
                    minFormat={displayMinCard()}
                    discount={
                        !displayMinCard() ?
                            <a onClick={handleApplyDiscount}>
                                {i18n.t('productAssigment.useDiscount')}
                            </a> :
                            <></>
                    }
                />
                {(needUserInfoForEntry(props.selection) || !displayMinCard()) &&
                    <Divider />
                }
                {!displayMinCard() &&
                    <FormatSelect
                        id={props.selection.id}
                        assigment={props.selection.assigment}
                        isGift={props.selection.isGift}
                        giftStatus={oc(props.selection).product.meta.giftStatus()}
                        tags={oc(props.selection).product.tags([])}
                        cardTypes={oc(props.selection).variant.tags([])}
                        showMethodSelect={!hideMethodSelect()}
                    />
                }
                {needUserInfoForEntry(props.selection) && (
                    <div className="product-assigner__card">
                        <div className="product-assigner__card__select">
                            <Select
                                dropdownMatchSelectWidth={false}
                                clickOnly
                                label={i18n.t('productAssigment.userDetails.label')}
                                onChange={(value) => setUserInfoModal(!!value)}
                                options={[{
                                    value: 1,
                                    label: <ProductAssignerLabel
                                        icon={<Icon type="user"/>}
                                        content={getUserDetailsLabel()}
                                    />,
                                }]}
                                value={1}
                                warning={!props.selection.userData}
                            />
                        </div>
                    </div>
                )}
            </Card>
        </div>
    );
}

export default ProductAssigner;
